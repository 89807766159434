<template>
  <PageCard
    pageName="FIN VendorFit"
    pageType="Select Institution"
    :iconClass="['cuis-puzzle-piece', 'card-icon-font']"
  >
    <template slot="page_content">
      <v-wait for="fetchingInstitutions">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="loader">
            <content-placeholders-heading />
            <content-placeholders-text :lines="13"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label for="Search" class="mb-2">
                <h6 class="mb-0">Select Institution</h6>
              </label>
              <multiselect
                track-by="id"
                label="display"
                v-model="selectedInstitution"
                :options="institutions"
                :multiple="false"
                :loading="isLoading"
                :close-on-select="true"
                placeholder="Search by FI Name, City, State or Federal Reserve ID"
                class="simple-select form-control vendor-fit"
                :showLabels="false"
                @search-change="debouncedInstitutions"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.full_name }}, {{ props.option.city }},
                  {{ props.option.state }}
                </template>
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group>
              <label for="Search" class="mb-2">
                <h6 class="mb-0">Select Solution</h6>
              </label>
              <multiselect
                track-by="id"
                label="name"
                v-model="selectedSolution"
                :options="solutions"
                :multiple="false"
                :close-on-select="true"
                placeholder="Vendor Solutions"
                class="simple-select form-control vendor-fit"
                :showLabels="false"
                :disabled="true"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-sm-12 text-center align-self-end mb-1">
            <b-form-group>
              <router-link
                tag="div"
                class="btn fw-btn btn-primary"
                :to="{
                  path: 'predictions',
                  query: {}
                }"
                :disabled="!enableAnalyze"
              >
                Analyze
              </router-link>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3" v-if="selectedInstitution">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h6 class="mb-2 d-inline-block">FI Summary</h6>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h6 class="mb-2 d-inline-block">Current Solution</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters" v-if="selectedInstitution">
          <div class="col-sm-12">
            <b-card-group deck class="w-100 m-0">
              <b-card class="std-border ml-0" body-class="p-2 px-3">
                <v-wait for="fetchingFIInfo">
                  <template slot="waiting">
                    <content-placeholders :rounded="true" class="loader">
                      <content-placeholders-heading />
                      <content-placeholders-text
                        :lines="5"
                      ></content-placeholders-text>
                    </content-placeholders>
                  </template>
                  <div class="row pb-2">
                    <div class="col-sm-1">
                      <div class="d-flex align-items-center">
                        <header-logo
                          logoType="FI"
                          :logoUrl="fiLogoURL"
                          :logoId="selectedInstitution.id"
                          class="justify-content-center"
                        ></header-logo>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <h6 class="mb-0">{{ profileDetail.name }}</h6>
                      <p class="mb-2">
                        {{ profileDetail.city }}, {{ profileDetail.state }}
                      </p>
                    </div>
                    <!-- isAnalyticsEnterpriseUser && -->
                    <div
                      class="col-sm-3 text-right"
                      v-if="canPurchase && showProfile"
                    >
                      <router-link
                        tag="a"
                        :to="{
                          path: '/analytics/fin_profile/custom_profile'
                        }"
                      >
                        FIN Profile
                      </router-link>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table>
                        <tr>
                          <td class="pr-4 font-weight-bold">Total Assets:</td>
                          <td>{{ profileDetail.assets }}</td>
                        </tr>
                        <tr>
                          <td class="pr-4 font-weight-bold">Branches:</td>
                          <td>{{ profileDetail.branches }}</td>
                        </tr>
                        <tr>
                          <td class="pr-4 font-weight-bold">
                            Market Coverage:
                          </td>
                          <td>{{ profileDetail.market_coverage }}</td>
                        </tr>
                        <tr>
                          <td class="pr-4 font-weight-bold">Website:</td>
                          <td>
                            <span
                              class="site-link"
                              v-if="
                                profileDetail.website_url &&
                                  profileDetail.website_url !== '0'
                              "
                            >
                              <a
                                target="_blank"
                                :href="formatLink(profileDetail.website_url)"
                              >
                                {{ profileDetail.website_url }}
                                <sup>
                                  <i
                                    class="fa fa-external-link"
                                    aria-hidden="true"
                                  ></i>
                                </sup>
                              </a>
                            </span>
                            <span v-else>NA</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-4 font-weight-bold">BHC:</td>
                          <td>{{ profileDetail.bhc }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-wait>
              </b-card>
              <b-card class="std-border mr-0" body-class="p-2 px-3">
                <v-wait for="fetchingSolutionInfo">
                  <template slot="waiting">
                    <content-placeholders :rounded="true" class="loader">
                      <content-placeholders-heading />
                      <content-placeholders-text
                        :lines="5"
                      ></content-placeholders-text>
                    </content-placeholders>
                  </template>
                  <div class="row">
                    <div class="col-sm-12">
                      <table>
                        <tr class="pb-2">
                          <td>
                            <header-logo
                              logoType="vendor"
                              :logoUrl="vendorLogoURL"
                              :logoId="solutionDetail.vendor_id"
                              class="justify-content-center mb-2"
                            ></header-logo>
                          </td>
                          <td class="px-4">
                            <b>
                              {{ solutionDetail.vendor }}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td><b>Product</b></td>
                          <td class="px-4">{{ solutionDetail.product }}</td>
                        </tr>
                        <tr>
                          <td><b>Clients</b></td>
                          <td class="px-4">
                            {{ solutionDetail.clients | numberFormat }}
                          </td>
                        </tr>
                        <tr>
                          <td><b>URL</b></td>
                          <td class="px-4">
                            <span
                              class="site-link"
                              v-if="
                                solutionDetail.url && solutionDetail.url !== '0'
                              "
                            >
                              <a
                                target="_blank"
                                :href="formatLink(solutionDetail.url)"
                              >
                                {{ solutionDetail.url }}
                                <sup>
                                  <i
                                    class="fa fa-external-link"
                                    aria-hidden="true"
                                  ></i>
                                </sup>
                              </a>
                            </span>
                            <span v-else>NA</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-wait>
              </b-card>
            </b-card-group>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12"></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12"></div>
            </div>
          </div>
        </div>
      </v-wait>
    </template>
  </PageCard>
</template>

<script charset="utf-8">
// global
import store from '@/store/store'
import router from '@/router/index'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
// ui components
import Multiselect from 'vue-multiselect'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
import PageCard from '@/modules/core/components/layouts/PageCard'
// api
import fitProfileAPI from '@/api/finapps/fit_profile'
// utils
import { formatUrl, s3URLFor } from '@/utilities'
import { debounce } from 'lodash'

const { mapFields } = createHelpers({
  getterType: 'VendorFit/getField',
  mutationType: 'VendorFit/updateField'
})

export default {
  name: 'SelectFitInstitution',
  components: {
    PageCard,
    HeaderLogo,
    Multiselect
  },
  data() {
    return {
      solutions: [],
      institutions: [],
      isLoading: false
    }
  },
  created() {
    this.loadInitData()
    this.debouncedInstitutions = debounce(this.loadInstitutions.bind(this), 300)
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showVendorFit']) {
      next()
    } else {
      router.push('/fi_prospect/about_vendor_fit')
    }
  },
  computed: {
    ...mapState('VendorFit', {
      profileDetail: state => state.profileDetail,
      solutionDetail: state => state.solutionDetail,
      selectedSolution: state => state.selectedSolution
    }),
    ...mapGetters('Ability', [
      'canPurchase',
      'isAnalyticsEnterpriseUser',
      'showProfile'
    ]),
    ...mapGetters('VendorFit', ['fiLogoURL']),
    ...mapFields(['selectedInstitution']),
    enableAnalyze() {
      return this.selectedInstitution && this.selectedSolution
    },
    vendorLogoURL() {
      if (!this.solutionDetail.vendor_id) {
        return '/'
      }

      return s3URLFor('vendor', this.solutionDetail.vendor_id)
    }
  },
  methods: {
    ...mapActions('VendorFit', ['fetchProfileDetail', 'fetchSolutionDetail']),
    ...mapMutations('VendorFit', [
      'setSelectedSolution',
      'setProfileDetail',
      'setSolutionDetail',
      'resetFIDependentData'
    ]),
    ...mapMutations('Profile', ['setInstitution', 'setReportId']),
    loadInitData() {
      this.$wait.start('fetchingInstitutions')

      Promise.all([this.loadInstitutions(), this.loadSolutions()]).then(() => {
        this.$wait.end('fetchingInstitutions')
      })
    },
    loadInstitutions(query) {
      this.isLoading = true

      return fitProfileAPI.institutions(query).then(institutions => {
        this.prepareInstitutions(institutions)
        this.isLoading = false
      })
    },
    loadSolutions() {
      return fitProfileAPI.solutions().then(solutions => {
        this.solutions = solutions

        let coreProcessing = this.solutions.find(
          solution => solution.name === 'Core Processing'
        )

        this.setSelectedSolution(coreProcessing)
      })
    },
    prepareInstitutions(institutions) {
      institutions.forEach(
        i => (i.display = `${i.full_name},${i.city},${i.state} [${i.id}]`)
      )
      this.institutions = institutions
    },
    fetchFIInfo() {
      this.$wait.start('fetchingFIInfo')

      this.fetchProfileDetail({ fiID: this.selectedInstitution.id }).then(
        () => {
          this.prepareFINProfileLink()
          this.$wait.end('fetchingFIInfo')
        }
      )
    },
    fetchSolutionInfo() {
      this.$wait.start('fetchingSolutionInfo')

      this.fetchSolutionDetail({
        fiID: this.selectedInstitution.id,
        solutionID: this.selectedSolution.id
      }).then(() => {
        this.$wait.end('fetchingSolutionInfo')
      })
    },
    formatLink(url) {
      return formatUrl(url)
    },
    prepareFINProfileLink() {
      this.setInstitution({
        id: this.profileDetail.id,
        name: this.profileDetail.name,
        fiType: this.profileDetail.fi_type
      })
      this.setReportId(null)
    }
  },
  watch: {
    selectedInstitution: {
      immediate: true,
      handler() {
        if (this.selectedInstitution && this.selectedInstitution.id) {
          this.resetFIDependentData()
          this.fetchFIInfo()
          this.fetchSolutionInfo()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.multiselect {
  &.vendor-fit {
    .multiselect__single {
      font-size: 15px;
    }

    .multiselect__input {
      width: 90% !important;
    }
  }
}
</style>
